import axios from "axios";

//ROLES
export const FACILITY_MANAGER = "facility_manager";
export const BRANCH_MANAGER = "branch_manager";
export const DIVISION_MANAGER = "division_manager";
export const APP_ONLY = "app_only";
export const FEEDBACK_ONLY = "feedback_only";
export const DEMO = "demo";

export const ROLES = {
  FacilityManager: "facility_manager",
  BranchManager: "branch_manager",
  DivisionManager: "division_manager",
  AppOnly: "app_only",
  SMSOnly: "sms_only",
  Demo: "demo",
};

export const ALL_USER_ROLES = [
  FACILITY_MANAGER,
  BRANCH_MANAGER,
  DIVISION_MANAGER,
  APP_ONLY,
  DEMO,
  FEEDBACK_ONLY,
];

export const ALL_USER_ROLES_BUT_FEEDBACK_ONLY = [
  FACILITY_MANAGER,
  BRANCH_MANAGER,
  DIVISION_MANAGER,
  APP_ONLY,
  DEMO,
];

export const PROFILE_IDS = {
  Nigeria: 39,
  MRC: 27,
  AXA: 29,
  MyClinics: 32,
  CJC: 35,
  FreshFoodMarkets: 48,
};

export const PROFILE_CATEGORY_IDS = {
  Healthcare: 1,
  FoodAndBeverage: 2,
  ECommerce: 3,
  InApp: 4,
};

//Answer Layouts
export const ANSWER_LAYOUT_TYPES = {
  multiple_choices: "multiple_choices",
  nps: "nps",
  short_text: "short_text",
  long_text: "long_text",
  numbers: "numbers_only",
  phone_number: "phone_number",
  voice_note: "voice_note",
};

//PROFILE FEATURES
export const CAN_MANAGE_DIVISIONS = "can_manage_divisions";
export const CAN_MANAGE_USERS = "can_manage_users";
export const CAN_SEND_SMS = "can_send_sms";
export const CAN_USE_SETTINGS = "can_use_settings";
export const CAN_USE_FEEDBACK_INVITATIONS = "can_use_feedback_invitation";
export const CAN_MANAGE_BRANCHES = "can_manage_branches";
export const CAN_ARCHIVE_REVIEWS = "can_archive_reviews";
export const CAN_USE_REPUTATION_DASHBOARD = "can_use_reputation_dashboard";
export const CAN_ARCHIVE_COMPLAINTS = "can_archive_complaints";
export const CAN_MANAGE_WORKSPACE = "can_manage_workspace";
export const CAN_MANAGE_LABELS = "can_manage_labels";

export const actionNotAllowedDemo =
  "This is a demo account, you can not perform this action";
export const valueChangeNotAllowedDemo =
  "This is a demo account, you can not change this value";
export const actionNotAllowedGeneral = "You can not perform this action";
export const valueChangeNotAllowedGeneral = "You can not change this value";
export const demoWarning =
  "This is a demo account, Some features might be hidden/disabled";
export const featureNotInPlanWarning =
  "Your current plan does not include this feature, please upgrade to use.";

//GOOGLE BUSINESS APIs
export const GOOGLE_CLIENT_ID =
  "226083468186-hn9j0ueeheq2auu408m15gkft28bu8ud.apps.googleusercontent.com";
export const GOOGLE_CLIENT_SECRET = "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";
export const GOOGLE_OAUTH_API_URL = "https://accounts.google.com/o/oauth2/v2";
export const GOOGLE_BUSSINESS_MANAGEMENT_API_URL =
  "https://mybusinessaccountmanagement.googleapis.com/v1";

// PRODUCTION
// export const URL = "https://api.meliorapp.com";
// export const API_URL = "https://api.meliorapp.com/v1";
// export const front = "https://dashboard.meliorapp.com";
// export const melior = axios.create({
//   baseURL: API_URL,
// });
// const GA_MEASUREMENT_ID_PROD = "G-BTQ7ECNHVV";

// STAGING
// export const URL = "https://staging.mymelior.com";
// export const API_URL = "https://staging.mymelior.com/v1";
// export const front = "https://app.mymelior.com";
// export const melior = axios.create({
//     baseURL: API_URL,
// });
// const GA_MEASUREMENT_ID_STAGING = "dqwdqdqw"

// DEVELOPMENT
export const URL = "https://api.dev.meliorapp.com";
export const API_URL = "https://api.dev.meliorapp.com/v1";
export const front = "https://dashboard.dev.meliorapp.com";
export const melior = axios.create({
  baseURL: API_URL,
});
const GA_MEASUREMENT_ID_DEV = "qwfqwfq";

export const GA_MEASUREMENT_ID = GA_MEASUREMENT_ID_DEV;
