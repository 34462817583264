import { useContext, useEffect, useState } from "react";
import { BranchContextProvider } from "./contexts/BranchContext";
import { BranchHeaderContextProvider } from "./contexts/BranchHeaderContext";

import { SideNavContextProvider } from "./contexts/SideNavContext";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ThemeProvider } from "@mui/material/styles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { customTheme } from "./helpers/Themes";
import {
  AuthenticationContext,
  AuthenticationContextProvider,
} from "./features/login";
import { AppRoutes } from "./routing/AppRoutes";
import { FEEDBACK_ONLY, GA_MEASUREMENT_ID } from "./helpers/constants";
import Header from "./layout/Header";
import {
  PaymentStatusMessage,
  usePaymentStatusInfo,
} from "./features/payment-funnel/PaymentStatusMessage";
import { Box, Button } from "@mui/material";
import ReactGA from "react-ga4";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  NotificationContext,
  NotificationContextProvider,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
} from "./contexts/NotificationContext";
import { NotificationComponent } from "./layout/NotificationComponent";
import { onMessageListener, requestForToken } from "./firebase";
import { ArrowForward } from "@mui/icons-material";
import { RevampedAppHeader } from "./layout/RevampedAppHeader";
import { PaymentStatusOverlay } from "./features/payment-funnel/PaymentStatusOverlay";
import { primaryColors } from "./helpers/customColors";

ReactGA.initialize(GA_MEASUREMENT_ID);

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  });

  return (
    <div className="App" style={{backgroundColor: primaryColors.gray[50], height: '100vh'}}>
      <NotificationContextProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={customTheme}>
            {/* <ErrorBoundary> */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <AuthenticationContextProvider history>
                <SideNavContextProvider>
                  <BranchContextProvider>
                    <BranchHeaderContextProvider>
                      <BrowserRouter>
                        <NotificationComponent />
                        <AppContent />
                      </BrowserRouter>
                    </BranchHeaderContextProvider>
                  </BranchContextProvider>
                </SideNavContextProvider>
              </AuthenticationContextProvider>
            </LocalizationProvider>
            {/* </ErrorBoundary> */}
          </ThemeProvider>
        </QueryClientProvider>
      </NotificationContextProvider>
    </div>
  );
}

const AppContent = () => {
  useListenToComplaintNotification();

  const [paymentStatusMsgOpen, setPaymentStatusMsgOpen] = useState(false);

  const { user } = useContext(AuthenticationContext);
  const {
    showPaymentStatus,
    currentPhaseIsCountdown,
    currentPhaseIsDeadline,
    currentPhaseIsGrace,
    paymentStatusMessage,
  } = usePaymentStatusInfo({
    user,
  });

  return (
    <Box display="flex" flexDirection="column">
      {currentPhaseIsCountdown && (
        <PaymentStatusMessage
          currentPhaseIsCountdown={currentPhaseIsCountdown}
          paymentStatusMessage={paymentStatusMessage}
          showPaymentStatus={showPaymentStatus}
          paymentStatusMsgOpen={paymentStatusMsgOpen}
          setPaymentStatusMsgOpen={setPaymentStatusMsgOpen}
        />
      )}
      {(currentPhaseIsGrace || currentPhaseIsDeadline) && (
        <PaymentStatusOverlay
          currentPhaseIsGrace={currentPhaseIsGrace}
          currentPhaseIsDeadline={currentPhaseIsDeadline}
        />
      )}
      <RevampedAppHeader paymentStatusMsgOpen={paymentStatusMsgOpen} />
      <AppRoutes />
    </Box>
  );
};

const useListenToComplaintNotification = () => {
  const { fireNotification, closeNotification } =
    useContext(NotificationContext);

  const [newComplaintID, setNewComplaintID] = useState(false);
  const [unseenComplaintsCounter, setUnseenComplaintsCounter] = useState(0);

  const goToComplaintsPageHandler = () => {
    navigate(`overall-view/complaints`);
    closeNotification();
  };

  const extraNotificationBody = (
    <Box display="flex" gap={3}>
      {unseenComplaintsCounter > 1 && (
        <Button
          sx={{
            color: "#F79009",
            fontSize: "16px",
            textTransform: "none",
            width: "fit-content",
            padding: 0,
          }}
          disableRipple
          variant="text"
          onClick={() => goToComplaintsPageHandler()}
        >
          Newly Received Complaints ({unseenComplaintsCounter})
        </Button>
      )}
      <Button
        sx={{
          color: "#B54708",
          fontSize: "16px",
          textTransform: "none",
          width: "fit-content",
          padding: 0,
        }}
        disableRipple
        variant="text"
        onClick={() => goToComplaintsPageHandler()}
        endIcon={<ArrowForward />}
      >
        View Complaints
      </Button>
    </Box>
  );

  const navigate = useNavigate();

  requestForToken();

  onMessageListener()
    .then((payload) => {
      const { id: complaintID } = payload.data;

      setNewComplaintID(complaintID);
      setUnseenComplaintsCounter(() => unseenComplaintsCounter + 1);

      fireNotification({
        title: "Just to let you know an end customer left a complaint.",
        description:
          "By addressing the complaint quickly, you can ensure customer loyalty.",
        extraDetailsToRender: extraNotificationBody,
        target: NOTIFICATIONS_TARGET.admin,
        type: NOTIFICATIONS_TYPE.error,
        onCloseNotificationCallback:
          function resetUnseenNotificationsCounter() {
            setUnseenComplaintsCounter(0);
          },
      });
    })
    .catch((err) =>
      console.error("onMessageListener - ComplainNotification.js", err)
    );
};

export default App;
